import React from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';

import Link from '../Link';

const ns = `rte`;

/*
* parses & renders html string into dom nodes
* (WYSIWYG field type from Wordpress)
*/

const RenderStaticHTML = ({ html, className }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[className]: className,
	});

	if (!html) return null;

	const elements = parse(html, {
		replace: domNode => {
			const {
				name,
				children,
				attribs,
			} = domNode;

			// render Link component for a tags

			if (name === 'a') {
				const linkText = children[0].data || '';

				/* eslint-disable */
				return <Link {...attribs}>{linkText}</Link>;
				/* eslint-enable */
			}

			return true;
		},
	});

	return (
		<div className={rootClassnames}>
			{elements}
		</div>
	);
};

export default RenderStaticHTML;
